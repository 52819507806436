import React, { useState, useEffect } from 'react';
import { getEstabelecimentos, atualizarStatusBloqueado, listarUsuariosPorEstabelecimento, criarPrimeiroUsuario, atualizarUsuario, getAutenticadorByEstabelecimento, criarAutenticador,  atualizarAutenticador} from '../services/apiTag';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const ListaClientesZenTag = ({ tokenZenTag }) => {
  const [clientes, setClientes] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState(null);
  const [modoEdicao, setModoEdicao] = useState(false); // Estado para definir se está editando um usuário
  const [usuarioEditandoId, setUsuarioEditandoId] = useState(null); // ID do usuário em edição
  const [novoUsuario, setNovoUsuario] = useState({
    nome: '',
    email: '',
    telefone: '',
    senha: '',
    estabelecimentoId: ''
  });

  useEffect(() => {
    if (!tokenZenTag) {
      setError('Token do ZenTag não encontrado.');
      setLoading(false);
      return;
    }

    async function fetchClientes() {
      try {
        const estabelecimentos = await getEstabelecimentos(tokenZenTag);
        setClientes(estabelecimentos);
      } catch (err) {
        setError('Erro ao carregar clientes do ZenTag.');
      } finally {
        setLoading(false);
      }
    }

    fetchClientes();
  }, [tokenZenTag]);

  // Função para alternar o status de bloqueado
  const handleAlterarBloqueado = async (id, statusAtual) => {
    try {
      const novoStatus = !statusAtual;
      await atualizarStatusBloqueado(id, novoStatus);

      setClientes((clientesAnteriores) =>
        clientesAnteriores.map((cliente) =>
          cliente.id === id ? { ...cliente, bloqueado: novoStatus } : cliente
        )
      );

      alert(`Cliente ${novoStatus ? 'bloqueado' : 'desbloqueado'} com sucesso!`);
    } catch (error) {
      console.error('Erro ao alterar status de bloqueado:', error);
      alert('Erro ao alterar status do cliente.');
    }
  };
  const [empresaSelecionada, setEmpresaSelecionada] = useState('');
  // Abrir modal para listar usuários do estabelecimento
  const handleShowUsuarios = async (cliente) => {
    setEstabelecimentoSelecionado(cliente.id);
    setEmpresaSelecionada(cliente.razaoSocial); // Agora usamos cliente.razaoSocial corretamente
    setShowModal(true);
    setModoEdicao(false);
    setUsuarioEditandoId(null);
    setNovoUsuario({
      nome: '',
      email: '',
      telefone: '',
      senha: '',
      estabelecimentoId: cliente.id
    });

    try {
      const usuarios = await listarUsuariosPorEstabelecimento(cliente.id);
      setUsuarios(usuarios);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      setUsuarios([]); // Garante que o modal abre mesmo sem usuários cadastrados
    }
  };


  // Fechar modal
  const handleCloseModal = () => {
    setShowModal(false);
    setUsuarios([]);
    setModoEdicao(false);
    setUsuarioEditandoId(null);
    setNovoUsuario({ nome: '', email: '', telefone: '', senha: '', estabelecimentoId: '' });
  };

  // Função para cadastrar ou editar um usuário
  const handleSalvarUsuario = async () => {
    try {
      if (modoEdicao) {
        await atualizarUsuario(usuarioEditandoId, novoUsuario);
        alert('Usuário atualizado com sucesso!');
      } else {
        await criarPrimeiroUsuario(novoUsuario);
        alert('Usuário cadastrado com sucesso!');
      }

      handleShowUsuarios(estabelecimentoSelecionado); // Atualiza a lista de usuários
    } catch (error) {
      console.error('Erro ao salvar usuário:', error);
      alert(error.response?.data || 'Erro ao salvar usuário.');
    }
  };

  // Função para editar um usuário
  const handleEditarUsuario = (usuario) => {
    setModoEdicao(true);
    setUsuarioEditandoId(usuario.id);
    setNovoUsuario({
      nome: usuario.nome,
      email: usuario.email,
      telefone: usuario.telefone,
      senha: '', // Senha vazia para não alterar
      estabelecimentoId: usuario.estabelecimentoId
    });
  };

  const [autenticador, setAutenticador] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);

  // 🔹 Abrir modal do autenticador
  const handleShowAutenticador = async (cliente) => {
    setEstabelecimentoSelecionado(cliente.id);
    setEmpresaSelecionada(cliente.razaoSocial);
    setShowAuthModal(true);

    try {
      let auth = await getAutenticadorByEstabelecimento(cliente.id);
      if (!auth) {
        auth = await criarAutenticador(cliente.id);
      }
      setAutenticador(auth);
    } catch (error) {
      console.error('Erro ao buscar/criar autenticador:', error);
    }
  };

  // 🔹 Regerar ClientSecret
  const handleRegerarAutenticador = async () => {
    try {
      const atualizado = await atualizarAutenticador(autenticador.id, estabelecimentoSelecionado);
      setAutenticador(atualizado);
      alert('Autenticador atualizado com sucesso!');
    } catch (error) {
      alert('Erro ao regerar autenticador.');
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="d-flex justify-content-center">Clientes Cadastrados no ZenTag</h2>

      {loading && <p>Carregando...</p>}
      {error && <p className="text-danger">{error}</p>}

      {!loading && !error && (
        <table className="table table-striped mt-4">
          <thead>
            <tr>
              <th>ID</th>
              <th>Razão Social</th>
              <th>CNPJ</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {clientes.length > 0 ? (
              clientes.map((cliente) => (
                <tr key={cliente.id}>
                  <td>{cliente.id}</td>
                  <td>{cliente.razaoSocial}</td>
                  <td>{cliente.cnpj}</td>
                  <td className={cliente.bloqueado ? 'text-danger' : 'text-success'}>
                    {cliente.bloqueado ? 'Bloqueado' : 'Ativo'}
                  </td>
                  <td>
                    <button
                      className={`btn btn-${cliente.bloqueado ? 'success' : 'danger'} me-2`}
                      onClick={() => handleAlterarBloqueado(cliente.id, cliente.bloqueado)}
                    >
                      {cliente.bloqueado ? 'Desbloquear Acesso' : 'Bloquear Acesso'}
                    </button>
                    <button className="btn btn-primary me-2" onClick={() => handleShowUsuarios(cliente)}>
                     Ver Usuários
                    </button>
                    <button className="btn btn-info" onClick={() => handleShowAutenticador(cliente)}>
                      Autenticador Raspberry
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">Nenhum cliente encontrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {/* Modal para listar usuários */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title><strong>{empresaSelecionada}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Lista de Usuários</h5>

          {/* 🔹 Adicionando um container com rolagem para a tabela */}
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {usuarios.length > 0 ? (
                  usuarios.map((usuario) => (
                    <tr key={usuario.id}>
                      <td>{usuario.id}</td>
                      <td>{usuario.nome}</td>
                      <td>{usuario.email}</td>
                      <td>
                        <button className="btn btn-warning btn-sm" onClick={() => handleEditarUsuario(usuario)}>
                          Editar
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">Nenhum usuário encontrado.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <h5 className="mt-4">{modoEdicao ? 'Editar Usuário' : 'Cadastrar Novo Usuário'}</h5>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={novoUsuario.nome}
                onChange={(e) => setNovoUsuario({ ...novoUsuario, nome: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={novoUsuario.email}
                onChange={(e) => setNovoUsuario({ ...novoUsuario, email: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                type="text"
                value={novoUsuario.telefone}
                onChange={(e) => setNovoUsuario({ ...novoUsuario, telefone: e.target.value })}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Senha {modoEdicao && '(Deixe em branco para manter a atual)'}</Form.Label>
              <Form.Control
                type="password"
                placeholder="Nova Senha"
                value={novoUsuario.senha}
                onChange={(e) => setNovoUsuario({ ...novoUsuario, senha: e.target.value })}
              />
            </Form.Group>

            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" onClick={handleCloseModal} className="me-2">
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleSalvarUsuario}>
                {modoEdicao ? 'Salvar Alterações' : 'Criar Usuário'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title><strong>{empresaSelecionada}</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {autenticador ? (
            <>
              <p><strong>Client ID:</strong> {autenticador.clientId}</p>
              <p><strong>Client Secret:</strong> {autenticador.clientSecret}</p>
              <Button variant="warning" onClick={handleRegerarAutenticador}>
                Regerar
              </Button>
            </>
          ) : (
            <p>Carregando...</p>
          )}
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default ListaClientesZenTag;
