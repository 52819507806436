import axios from 'axios';

const apiTag = axios.create({
  baseURL: process.env.REACT_APP_APITAG_URL, // URL da API do ZenTag
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor para adicionar o token automaticamente nas requisições
apiTag.interceptors.request.use(
  (config) => {
    const tokenZenTag = sessionStorage.getItem('tokenZenTag'); // Pegando o token armazenado na sessão
    if (tokenZenTag) {
      config.headers.Authorization = `Bearer ${tokenZenTag}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 🔹 Listar todos os estabelecimentos
export const getEstabelecimentos = async () => {
  try {
    const response = await apiTag.get('/estabelecimentos');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar estabelecimentos no ZenTag:', error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Buscar estabelecimento por ID
export const getEstabelecimentoById = async (id) => {
  try {
    const response = await apiTag.get(`/estabelecimentos/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Erro ao buscar estabelecimento ID ${id}:`, error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Função para buscar um estabelecimento pelo CNPJ
export const getEstabelecimentoByCNPJ = async (cnpj) => {
  try {
    const response = await apiTag.get('/estabelecimentos');
    const estabelecimento = response.data.find((est) => est.cnpj === cnpj);
    return estabelecimento || null;
  } catch (error) {
    console.error('Erro ao buscar estabelecimento no ZenTag:', error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Cadastrar um novo estabelecimento
export const cadastrarEstabelecimento = async (dadosEstabelecimento) => {
  try {
    const response = await apiTag.post('/estabelecimentos', dadosEstabelecimento);
    return response.data;
  } catch (error) {
    console.error('Erro ao cadastrar estabelecimento no ZenTag:', error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Atualizar um estabelecimento existente
export const atualizarEstabelecimento = async (id, dadosEstabelecimento) => {
  try {
    await apiTag.put(`/estabelecimentos/${id}`, dadosEstabelecimento);
    return { success: true };
  } catch (error) {
    console.error(`Erro ao atualizar estabelecimento ID ${id}:`, error.response?.data || error.message);
    throw error;
  }
};



export const atualizarStatusBloqueado = async (id, bloqueado) => {
  try {
    // 🔹 Primeiro, buscamos o estabelecimento atual
    const estabelecimentoAtual = await getEstabelecimentoById(id);

    if (!estabelecimentoAtual) {
      throw new Error(`Estabelecimento ID ${id} não encontrado.`);
    }

    // 🔹 Criamos um objeto atualizado, mantendo todos os outros campos
    const estabelecimentoAtualizado = {
      ...estabelecimentoAtual, // Mantém os outros dados do estabelecimento
      bloqueado, // Apenas alteramos o status de bloqueio
    };

    // 🔹 Agora, enviamos a requisição PUT com os dados completos
    await apiTag.put(`/estabelecimentos/${id}`, estabelecimentoAtualizado);

    return { success: true };
  } catch (error) {
    console.error(`Erro ao atualizar status de bloqueio do estabelecimento ID ${id}:`, error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Excluir um estabelecimento
export const deletarEstabelecimento = async (id) => {
  try {
    await apiTag.delete(`/estabelecimentos/${id}`);
    return { success: true };
  } catch (error) {
    console.error(`Erro ao excluir estabelecimento ID ${id}:`, error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Função para listar os usuários de um estabelecimento
export const listarUsuariosPorEstabelecimento = async (estabelecimentoId) => {
  try {
    const response = await apiTag.get(`/usuarios/estabelecimento/${estabelecimentoId}`);
    return response.data;
  } catch (error) {
    console.error(`Erro ao buscar usuários do estabelecimento ID ${estabelecimentoId}:`, error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Função para criar um novo usuário no ZenTag
export const criarPrimeiroUsuario = async (usuario) => {
  try {
    const response = await apiTag.post('/usuarios/primeiro-usuario', usuario);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar primeiro usuário:', error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Atualizar usuário existente (Nome, Email, Telefone, Senha)
export const atualizarUsuario = async (id, dadosUsuario) => {
  try {
    const response = await apiTag.put(`/usuarios/${id}/atualizar`, dadosUsuario);
    return response.data;
  } catch (error) {
    console.error(`Erro ao atualizar usuário ID ${id}:`, error.response?.data || error.message);
    throw error;
  }
};

// 🔹 Buscar autenticador do estabelecimento
export const getAutenticadorByEstabelecimento = async (estabelecimentoId) => {
  try {
    const response = await apiTag.get(`/autenticadores`);
    return response.data.find(auth => auth.estabelecimentoId === estabelecimentoId) || null;
  } catch (error) {
    console.error(`Erro ao buscar autenticador do estabelecimento ID ${estabelecimentoId}:`, error);
    throw error;
  }
};

// 🔹 Criar novo autenticador
export const criarAutenticador = async (estabelecimentoId) => {
  try {
    const novoAutenticador = {
      estabelecimentoId,
      clientId: generateRandomString(16),
      clientSecret: generateRandomString(32)
    };
    const response = await apiTag.post('/autenticadores', novoAutenticador);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar autenticador:', error);
    throw error;
  }
};

// 🔹 Atualizar autenticador (regerar clientSecret)
export const atualizarAutenticador = async (id, estabelecimentoId) => {
  try {
    const atualizado = {
      id,
      estabelecimentoId,
      clientId: generateRandomString(16),
      clientSecret: generateRandomString(32)
    };
    await apiTag.put(`/autenticadores/${id}`, atualizado);
    return atualizado;
  } catch (error) {
    console.error(`Erro ao atualizar autenticador ID ${id}:`, error);
    throw error;
  }
};

// 🔹 Função para gerar strings aleatórias
const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export default apiTag;
