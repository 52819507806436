import React from 'react';
import CadastroUsuario from './CadastroUsuario';
import { cadastrarUsuario } from '../services/api';

const NovoUsuario = ({ token }) => {
  const handleCreateSubmit = async (data, token) => {
    try {
      await cadastrarUsuario(data, token);
      alert('Usuário cadastrado com sucesso!');
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
    }
  };

  return (
    <CadastroUsuario 
      token={token} 
      onSubmit={handleCreateSubmit} 
      isEdit={false} 
    />
  );
};

export default NovoUsuario;
