import React, { useState } from 'react';
import { login } from '../services/api';
import { useNavigate } from 'react-router-dom';

const Login = ({ setToken, setUserName, setTokenZenTag }) => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login({ email, senha });
      const { token, userName, tokenZenTag } = response.data;
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('userName', userName);
      sessionStorage.setItem('tokenZenTag', tokenZenTag);
      setToken(token);
      setUserName(userName);
      setTokenZenTag(tokenZenTag)
      navigate('/home');
    } catch (error) {
      console.error('Erro ao fazer login', error);
      alert('Erro ao fazer login, verifique suas credenciais.');
    }
  };

  return (
    <div className="container">      
      <div className="row justify-content-center mt-5">
      <div className="card col-md-4">
      <h2 className="justify-content-center"> Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="senha">Senha</label>
            <input
              type="password"
              className="form-control"
              id="senha"
              name="senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary mt-1 mb-1">Entrar</button>
          </div>
        </form>
      </div>
      </div>
    </div>
  );
};

export default Login;
