import React from 'react';
import CadastroCliente from './CadastroCliente';
import { cadastrarCliente } from '../services/api';

const NovoCliente = ({ token }) => {
  const handleSubmit = async (data) => {
    try {
      await cadastrarCliente(data, token);
      alert('Cliente cadastrado com sucesso!');
    } catch (error) {
      console.error('Erro ao cadastrar cliente:', error);
      alert('Erro ao cadastrar cliente.');
    }
  };

  return (
    <CadastroCliente onSubmit={handleSubmit} token={token} />
  );
};

export default NovoCliente;
