import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

const CadastroUsuario = ({ token, initialData = {}, onSubmit, isEdit = false }) => {
  const [usuario, setUsuario] = useState({
    nome: '',
    telefone: '',
    email: '',
    senha: '',
    ...initialData // Carrega os dados iniciais se estiver em modo de edição
  });

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      setUsuario({
        nome: initialData.nome || '',
        telefone: initialData.telefone || '',
        email: initialData.email || '',
        senha: initialData.senha || ''
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario({
      ...usuario,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const usuarioPayload = {
      Nome: usuario.nome,
      Telefone: usuario.telefone,
      Email: usuario.email,
      Senha: usuario.senha
    };

    try {
      await onSubmit(usuarioPayload, token);
      alert(isEdit ? 'Usuário atualizado com sucesso!' : 'Usuário cadastrado com sucesso!');
    } catch (error) {
      if (error.response) {
        console.error(`Erro ao ${isEdit ? 'atualizar' : 'cadastrar'} usuário:`, error.response.data);
      } else {
        console.error(`Erro ao ${isEdit ? 'atualizar' : 'cadastrar'} usuário`, error);
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <h2 className="d-flex justify-content-center">{isEdit ? 'Editar Usuário' : 'Cadastro de Usuário'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nome">Nome</label>
            <input
              type="text"
              className="form-control"
              id="nome"
              name="nome"
              value={usuario.nome}
              onChange={handleChange}
              required={!isEdit} // Nome é obrigatório no cadastro
            />
          </div>
          <div className="form-group">
            <label htmlFor="telefone">Telefone</label>
            <InputMask
              mask="(99) 9999-9999"
              type="text"
              className="form-control"
              id="telefone"
              name="telefone"
              value={usuario.telefone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={usuario.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="senha">Senha</label>
            <input
              type="password"
              className="form-control"
              id="senha"
              name="senha"
              value={usuario.senha}
              onChange={handleChange}
              required={!isEdit} // Senha é obrigatória no cadastro
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary">{isEdit ? 'Salvar' : 'Cadastrar'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CadastroUsuario;
