import React, { useEffect, useState } from 'react';
import { listarUsuarios, excluirUsuario } from '../services/api';
import { useNavigate } from 'react-router-dom';

const ListaUsuarios = ({ token }) => {
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listarUsuarios(token);
        setUsuarios(response.data);
      } catch (error) {
        console.error('Erro ao listar usuários:', error);
      }
    };
    fetchData();
  }, [token]);

  const handleEditar = (id) => {
    navigate(`/usuarios/editar/${id}`);
  };

  const handleExcluir = async (id) => {
    try {
      await excluirUsuario(id, token);
      setUsuarios(usuarios.filter(usuario => usuario.id !== id));
      alert('Usuário excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
      alert('Erro ao excluir usuário!');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Listagem de Usuários</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario) => (
            <tr key={usuario.id}>
              <td>{usuario.email}</td>
              <td>{usuario.nome}</td>
              <td>
                <button 
                  className="btn btn-primary" 
                  onClick={() => handleEditar(usuario.id)}
                >
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaUsuarios;
